/** @jsx jsx */
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { jsx, Themed } from 'theme-ui'
import { Layout, Section, SEO } from '../components'
import { TourCategories } from '../components/TourCategeory/TourCategory'
import linkResolver from '../utils/linkResolver'

export const query = graphql`
  query {
    allPrismicTour {
      nodes {
        _previewable
        url
        id
        data {
          meta_description
          meta_title
          og_image {
            url
            alt
          }
          title {
            text
          }
          subtitle {
            text
          }
          image {
            gatsbyImageData
          }
          priority
          category {
            document {
              ... on PrismicTourCategory {
                _previewable
                id
              }
            }
          }
        }
      }
    }
    allPrismicTourCategory {
      nodes {
        _previewable
        id
        data {
          title {
            text
            raw
          }
          description {
            raw
          }
          priority
          color
          image {
            gatsbyImageData
          }
        }
      }
    }
  }
`

const Page = ({ data }) => {
  const tourCategories = data.allPrismicTourCategory.nodes
    .sort((a, b) => b.data.priority - a.data.priority)
    .map((category) => {
      category.data.tours = []
      return category
    })

  tourCategories.push({
    id: 'withoutCategory',
    data: {
      tours: [],
      title: {
        text: 'Weitere Führungen',
      },
    },
  })

  // TODO: Remove hard coded fallback-category in favor of a prismic sourced category?
  data.allPrismicTour.nodes.forEach((tour) => {
    const categoryId = get(tour, 'data.category.document.id', 'withoutCategory')

    tourCategories
      .find((category) => category.id === categoryId)
      .data.tours.push(tour)
  })

  // manual sorting to handle tours with priority 'null' correctly (show them last)
  tourCategories.forEach((category) => {
    category.data.tours = category.data.tours.sort(
      (a, b) => b.data.priority - a.data.priority
    )
  })

  return (
    <Layout>
      <SEO title="Themen & Touren" /> {/* TODO: Add meta infos */}
      <Section>
        <Themed.h2 as="h1" sx={{ textAlign: 'center' }}>
          Themen & Touren
        </Themed.h2>
      </Section>
      <TourCategories categories={tourCategories} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object,
}

export default withPrismicPreview(Page, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
