/** @jsx jsx */
import React from 'react'
import { Flex, jsx, Themed } from 'theme-ui'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../../utils/htmlSerializer'

import { Image, Section, TourCard } from '..'

export const TourCategory = (props) =>
  props.tours.length > 0 ? (
    <Section
      sx={{ bg: props.odd ? null : 'white' }}
      className={props.className}
    >
      <Flex sx={{ flexWrap: 'wrap', mx: -3 }}>
        <Flex
          sx={{
            bg: props.color || 'accent',
            borderRadius: 'md',
            color: 'white',
            flex: (t) => [
              `0 1 100%`,
              null,
              `0 1 calc(${(2 / 3) * 100}% - ${t.space[3] * 2}px)`,
            ],
            flexDirection: 'column',
            justifyContent: 'flex-end',
            m: 3,
            minHeight: 420,
            p: 4,
            position: 'relative',
          }}
        >
          {props.image && (
            <Image
              alt=""
              image={props.image.gatsbyImageData}
              sx={{
                position: 'absolute !important',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                opacity: 0.1,
                mixBlendMode: 'darken',
              }}
            />
          )}
          <div sx={{ maxWidth: [null, '75%'], position: 'relative' }}>
            <Themed.h3
              as="h2"
              sx={{ mt: 0 }}
              dangerouslySetInnerHTML={{ __html: props.title.text }}
            />
            <RichText
              render={props.description.raw || []}
              htmlSerializer={htmlSerializer}
            />
          </div>
        </Flex>
        {props.tours.map((tour, index) => (
          <TourCard {...tour.data} url={tour.url} key={index} />
        ))}
      </Flex>
    </Section>
  ) : null

TourCategory.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.shape({
    raw: PropTypes.array,
  }),
  image: PropTypes.shape({
    fluid: PropTypes.object,
  }),
  odd: PropTypes.bool,
  title: PropTypes.shape({
    text: PropTypes.string,
  }),
  tours: PropTypes.array,
}

TourCategory.defaultProps = {
  description: {},
  title: {},
}

export const TourCategories = (props) => (
  <React.Fragment>
    {props.categories.map((category, index) => (
      <TourCategory {...category.data} odd={index % 2 !== 0} key={index} />
    ))}
  </React.Fragment>
)

TourCategories.propTypes = {
  categories: PropTypes.array.isRequired,
}
